import React from 'react';
import styled from 'styled-components';

const Vertical = styled.div`

font-family: Almarai;

@media only screen and (min-width: 992px) {

  text-decoration: none;
  background-repeat: no-repeat;

  transition: background-size 0.5s;
  position: relative;
  font-size: 26px;
  text-align: right;
  margin: 0 1rem auto auto;
  padding: 0;

  &::before,&::after {
    position: absolute;
    content: "";
    bottom: 5%;
    right: ${(props) => (props.right ? `${props.right}rem` : '-0.5rem')};
    height: 90%;
    min-height: 40px;
    width: 1px;
    /*margin: 5px 0 0;*/
    transition: all 0.2s ease-in-out;
    transition-duration: 0.3s;
    opacity: 1;
    /*background-image: linear-gradient(transparent 5px, rgb(250,53,105)  calc(100% - 5px));*/
    background: lightgrey;
    background-size: 100% 100%;
    /*z-index: 2;*/
  }


}

  @media only screen and (max-width: 600px){

  }
  @media only screen and (max-width: 992px) {

  }
`;

export default Vertical;
