import React from 'react';
import styled from 'styled-components';

export const StyledMenu = styled.nav`
  overflow-y: scroll;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => '#f0f0f0'};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  text-align: left;
  padding: 4rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.2s ease-in-out;

  @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100%;
    }
    section{
      h3 {
          color: ${({ theme }) => theme.primaryHover};
          border-bottom: 1px solid #111;
          /*width: 95%;*/
          padding-bottom: 10px
      }

            a {
              font-size: 1rem;
              text-transform: uppercase;
              color: #4c4c4c;
              padding: 1rem 0;
              font-weight: bold;
              letter-spacing: 0.2rem;
              line-height: 2rem;
              color: ${({ theme }) => theme.primaryDark};
              text-decoration: none;
              transition: color 0.3s linear;

              @media (max-width: ${({ theme }) => theme.mobile}) {
                font-size: 1.5rem;
                text-align: center;
              }

              &:hover {
                color: ${({ theme }) => 'rgb(250,53,105)'};
              }
              span {
                font-size: 1.5rem;
              }
            }

    }
`;
