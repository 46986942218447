import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { usePosition } from 'use-position';
import dayjs from 'dayjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactWeather from './components/ReactWeather';
import { useOpenWeather } from './openweather';
import { getIcon } from './openweather/iconsMap';

export const formatDate = (dte) => {
  if (dte && dayjs().isValid(dte)) {
    return dayjs.unix(dte).format('ddd D MMMM');
  }
  return '';
};

export const mapCurrent = (day, lang) => ({
  date: formatDate(day.dt, lang),
  description: day.weather[0] ? day.weather[0].description : null,
  icon: day.weather[0] && getIcon(day.weather[0].icon),
  temperature: {
    current: day.temp.toFixed(0),
    min: undefined, // openweather doesnt provide min/max on current weather
    max: undefined,
  },
  wind: day.wind_speed.toFixed(0),
  humidity: day.humidity,
});

export const mapForecast = (forecast, lang) => {
  const mappedForecast = [];
  for (let i = 0; i < 5; i += 1) {
    mappedForecast.push({
      date: formatDate(forecast[i].dt, lang),
      description: forecast[i].weather[0]
        ? forecast[i].weather[0].description
        : null,
      icon: forecast[i].weather[0] && getIcon(forecast[i].weather[0].icon),
      temperature: {
        min: forecast[i].temp.min.toFixed(0),
        max: forecast[i].temp.max.toFixed(0),
      },
      wind: forecast[i].wind_speed.toFixed(0),
      humidity: forecast[i].humidity,
    });
  }
  return mappedForecast;
};

export const mapData = (forecastData, todayData, lang) => {
  const mapped = {};
  if (forecastData && todayData) {
    const daysData = forecastData;
    mapped.current = mapCurrent(todayData, lang);
    mapped.forecast = mapForecast(daysData);
  }
  return mapped;
};

export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

const initialState = {
  data: null,
  isLoading: true,
  errorMessage: null,
};

export const fetchReducer = (state, { type, payload }) => {
  switch (type) {
    case SUCCESS:
      return {
        data: payload,
        isLoading: false,
        errorMessage: null,
      };
    case FAILURE:
      return { data: null, isLoading: false, errorMessage: payload };
    default:
      return state;
  }
};

const WeatherComponent = () => {
  const [position, setPosition] = useState({});
  const [weather, setWeather] = useState({});
  const [city, setCity] = useState('');

  let {
    latitude,
    longitude,
    speed,
    timestamp,
    accuracy,
    error,
  } = usePosition();

  // const endpoint = 'http://api.openweathermap.org/data/2.5/onecall';// http://api.openweathermap.org/data/2.5/weather
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const { data, isLoading, errorMessage } = state;

  latitude = latitude || '48.137154';
  longitude = longitude || '11.576124';

  useEffect(() => {
    if (!latitude) return;
    const lang = 'en';
    const params = {
      appid: '24724987b79bb213ce62c0d50aa3880f',
      units: 'metric',
      lat: latitude || '48.137154',
      lon: longitude || '11.576124',
      lang,
    };

    const fetchData = async () => {
      try {
        const forecastResponse = await axios.get('http://api.openweathermap.org/data/2.5/onecall', { params });
        const payload = mapData(
          forecastResponse.data.daily,
          forecastResponse.data.current,
          forecastResponse.data.timezone,
          lang,
        );

        dispatch({
          type: SUCCESS,
          payload,
        });
      } catch (error) {
        console.error(error.message);
        dispatch({ type: FAILURE, payload: error.message || error });
      }
    };

    const getCity = async () => {
      const endpoint = 'http://api.openweathermap.org/data/2.5/weather';// http://api.openweathermap.org/data/2.5/weather

      try {
        const forecastResponse = await axios.get('http://api.openweathermap.org/data/2.5/weather', { params });
        setCity(forecastResponse.data.name);
      } catch (error) {
        console.error(error.message);
      }
    };
    getCity();
    setTimeout(fetchData, 500);
  }, [latitude, longitude, accuracy, error, speed, timestamp]);

  return (
    (latitude && longitude && !state.isLoading) ? (
      <ReactWeather
        isLoading={state.isLoading}
        errorMessage={state.errorMessage}
        data={state.data}
        lang="en"
        locationLabel={city}
        unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
        showForecast
      />
    ) : <CircularProgress disableShrink />
  );
};

export default WeatherComponent;
