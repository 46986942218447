import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';

// @TODO https://speckyboy.com/underline-text-effects-css/
// https://codepen.io/mxttsco/pen/KgodYm

const Post = styled.li`
  position: relative;
  border: 1px solid ${(props) => props.theme.palette.secondary};
  /*border-radius: 2px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;*/
  list-style-type: none;
  margin: 0 0 1em 0;
  padding: 1rem;
  width: 100%;
  text-align: right;
  background-color: white;
  /*transition: background 0.2s;*/
  @media screen and (min-width: ${(props) => '480px'}) {
    flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${(props) => '760px'}) {
    flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 32%')};
  }
  /*&:hover {
    background: ${(props) => props.theme.secondary};
  }*/
  /*transition: all .3s ease;
  box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);
  &:hover {
    transform: translate(0px, -2px);
    box-shadow: 0 15px 45px -10px rgba(10, 16, 34, .2);
  }*/

  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${(props) => '#727272'};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
      @media screen and (min-width: ${(props) => '480px'}) {
        padding-bottom: ${(props) => (props.featured ? '40%' : '60%')};
      }
    }
  }
`;

const StyledImg = styled(Img)`
  /*border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;*/
  border-radius: 0;
`;

const MyStyledImg = styled.img`
  /*border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;*/
  border-radius: 0;
  width: 100%;
`;

/** Hover-related CSS * */
/*  const Title = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 0 45px;

    span {
      transition: all 0.2s ease-in-out;
      font-size: 36px;
      text-align: center;
      margin: 0 auto;
      padding: 0;
      &:after, &:before {
        content: "";
        position: absolute;
        bottom: -10px;
        height: 5px;
        margin: 5px 0 0;
        background-image: linear-gradient(to right, transparent calc(100% - 10px), orange 10px)
        width: 100%;
        opacity: 0;
        right: 0;
        &:hover {
          cursor: pointer;
          span {
            &:before,
            &:after {
              width: 100%;
              opacity: 1;
            }
          }
        }
      }
    }
  `
  */

/* undesired nice effect @TODO  mytodo
  const Title = styled.h2`
    font-size: 1.5em;
    font-weight: 600;
    text-transform: capitalize;
    margin: 1rem 1rem 0.5rem 1rem;
    border-bottom: 5px solid black;
    background-image: linear-gradient(to right, transparent calc(100% - 10px), orange 10px);
    background-size: 0% 100%;
    background-position: bottom left;
    transition: all 5s ease-out;

    &:hover {
      background-size: 200% 100%;
      opacity: 1;
    }
  ` */

const Date = styled.h3`
  margin: 0 1rem 0.5rem 1rem;
  color: gray;
`;

const ReadingTime = styled.h6`
  margin: 0 1rem 1.5rem 1rem;
  color: gray;
`;

const Excerpt = styled.div`
  margin: 0 1rem 1rem 1rem;
  line-height: 1.6;
`;

const More = styled.span`
  color: theme.palette.primary;
  transition: "transform .5s ease";
  margin: 0 1rem 0rem 1rem;
  &:hover {
    color: red;
  }
`;

const useStyles = makeStyles({
  more: {
    color: 'red',
  },
  almarai: {
    fontFamily: 'Almarai',
  },

});

const PortraitCard = ({
  slug, featuredImage, title, date, excerpt, timeToRead, ...props
}) => {
  const classes = useStyles();

  return (
    <>
      {featuredImage && (
      <Post featured={props.featured}>
        <Link to={`/${slug}/`}>
          <Img
            fluid={featuredImage.childImageSharp.fluid}
            alt="Gatsby Docs are awesome"
          />

          <Title>
            {title}
          </Title>

          <Date>
            {' '}
            <Typography
              type="div"
              style={{
                fontFamily: 'Almarai',
                textAlign: 'justify',
              }}
            >
              {date}
            </Typography>
          </Date>
          <ReadingTime>
            <Typography
              type="div"
              style={{
                fontFamily: 'Almarai',
                textAlign: 'justify',
              }}
            >
              {timeToRead === 1 ? `${timeToRead} دقيقة للقراءة` : timeToRead > 1 ? `${timeToRead} دقائق للقراءة` : ''}
            </Typography>

          </ReadingTime>
          <Excerpt>
            <Typography
              type="div"
              style={{
                fontFamily: 'Almarai',
                textAlign: 'justify',
              }}
            >

              {excerpt}

            </Typography>
          </Excerpt>
          {/* <More>

            <Typography
              type="div"
              style={{
                fontFamily: 'Almarai',
                textAlign: 'justify',
              }}
            >
              المزيد
            </Typography>
          </More> */}

          <Button size="small">
            <Typography className={classNames({ [classes.more]: true, [classes.almarai]: true })} component="div">

              المزيد
            </Typography>
          </Button>

        </Link>
      </Post>
      )}
    </>
  );
};

export default PortraitCard;

/*
import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Post = styled.li`
  position: relative;
  border: 1px solid #0D0C1D;
  border-radius: 2px;
  margin: 0 0 1em 0;
  width: 100%;
  transition: background 0.2s;
  @media screen and (min-width: 480px) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: 768px) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
  }
  &:hover {
    background: #0D0C1D;
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: black;
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
      @media screen and (min-width: 480px) {
        padding-bottom: ${props => (props.featured ? '40%' : '60%')};
      }
    }
  }
`

const StyledImg = styled(Img)`
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
`

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  text-transform: capitalize;
  margin: 1rem 1rem 0.5rem 1rem;
`

const Date = styled.h3`
  margin: 0 1rem 0.5rem 1rem;
  color: gray;
`

const ReadingTime = styled.h4`
  margin: 0 1rem 1.5rem 1rem;
  color: gray;
`

const Excerpt = styled.p`
  margin: 0 1rem 1rem 1rem;
  line-height: 1.6;
`

const Card = ({ slug, featuredImage, title, date, excerpt, ...props }) => {
  return (
    <>
      {featuredImage  && (
        <Post featured={props.featured}>
          <Link to={`${props.basePath}/${slug}/`}>
            <StyledImg fluid={featuredImage.fluid} backgroundColor={'#eeeeee'} />
            <Title>{title}</Title>
            <Date>{date}</Date>
            <ReadingTime>
              {2+3} min read
            </ReadingTime>
            <Excerpt
              dangerouslySetInnerHTML={{
                __html: excerpt,
              }}
            />
          </Link>
        </Post>
      )}
    </>
  )
}

export default Card
*/
