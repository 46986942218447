/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// ./gatsby-browser.js
/* eslint-disable react/prop-types, import/no-extraneous-dependencies */
require("typeface-almarai")
const React = require("react")
const Layout = require("./src/components/layout").default
const LocaleContext = require("./src/components/layout").LocaleContext

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

exports.wrapRootElement = ({ element }) => {
  return <LocaleContext.Provider>{element}</LocaleContext.Provider>
}
