/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description,
  lang,
  meta,
  image: metaImage,
  keywords,
  title,
  pathname,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  );
  title = title || 'norafpost';

  const metaDescription = description || site.siteMetadata.description;
  const image = metaImage && metaImage.src
    ? `${site.siteMetadata.siteUrl}${metaImage.src}`
    : null;

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

  return (
    <>
      <Helmet
      /* bodyAttributes={{
        dir: "rtl"
      }} */
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        link={
        canonical
          ? [
            {
              rel: 'canonical',
              href: canonical,
            },
          ]
          : []
      }
        meta={[
          { name: 'Content-Type', content: 'text/html; charset=utf-8' },
          {
            name: 'description',
            content: metaDescription,
          },
          {
            name: 'google-site-verification',
            content: '3qb3OpbmXvQ8MjK2hlGZ_9xllPMOlVjHhxOFmbfLmXI',
          },

          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: site.siteMetadata.author,
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            name: 'twitter:description',
            content: metaDescription,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
              : [],

            metaImage
              ? [
                {
                  property: 'og:image',
                  content: image,
                },
                {
                  property: 'og:image:width',
                  content: metaImage.width,
                },
                {
                  property: 'og:image:height',
                  content: metaImage.height,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ]
              : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ],
          )
          .concat(meta)}
      />
    </>
  );
}

SEO.defaultProps = {
  lang: 'ar',
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
};

export default SEO;
