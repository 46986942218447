/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import LocalizedLink from "../../components/localizedLink";
import Typography from '@material-ui/core/Typography';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { green } from '@material-ui/core/colors';

// @material-ui/icons
import { Home } from "@material-ui/icons";

// core components
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.js";
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>

      <ListItem className={classes.listItem}>
      <LocalizedLink to={"/"}>
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <Home className={classes.icons} style={{ color: 'rgb(250,53,105)' }} />
          <Typography
            type="div"
            style={{
              fontFamily: 'Almarai',
              textAlign: 'justify',
              color: 'rgb(250,53,105)'
            }}
          >
            الصفحة الرئيسية
          </Typography>

        </Button>
        </LocalizedLink>
      </ListItem>

    </List>
  );
}
