// Only one item MUST have the "default: true" key

module.exports = {
  /*en: {
    default: false,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Using i18n with Gatsby`,
    defaultDescription: `Gatsby example site using MDX and dependency-free i18n`
  },
  de: {
    path: `de`,
    locale: `de-DE`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `de`,
    ogLanguage: `de_DE`,
    defaultTitle: `i18n mit Gatsby nutzen`,
    defaultDescription: `Gatsby Beispielseite, die MDX und i18n (frei von dependencies) nutzt`
  },
  fr: {
    path: `fr`,
    locale: `fr-FR`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `fr`,
    ogLanguage: `fr_FR`,
    defaultTitle: `utiliser i18n avac Gatsby`,
    defaultDescription: `Exemples de Gatsby, qui utilisent MDX et i18n libres des dependences`
  },
  es: {
    path: `es`,
    locale: `es-ES`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `es`,
    ogLanguage: `es_ES`,
    defaultTitle: `utilizar i18n con Gatsby`,
    defaultDescription: `Ejemplos`
  },
  pt: {
    path: `pt`,
    locale: `pt-BR`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `pt`,
    ogLanguage: `pt_BR`,
    defaultTitle: `utilizar i18n com Gatsby`,
    defaultDescription: `muito legal`
  },*/

  ar: {
    default: true,
    path: `ar`,
    locale: `ar-MA`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `ar`,
    ogLanguage: `ar_MA`,
    defaultTitle: `i18n استعمل كاتسبي مع`,
    defaultDescription: `امثلة كاتسبي`
  }/*
  id: {
    path: `id`,
    locale: `id`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `id`,
    ogLanguage: `id`,
    defaultTitle: `menyempunyai`,
    defaultDescription: `terima kasih`
  },*/
}
