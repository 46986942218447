import React from 'react';
import { bool } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useStaticQuery, graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import { StyledMenu } from './Menu.styled';
import useTranslations from '../useTranslations';

const Menu = ({ open, ...props }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            components {
              menu {
                links {
                  name
                  icon
                  childNodes {
                    name
                    icon
                  }
                }
              }
            }
          }
        }
      }
    `,
  );

  const isHidden = !!open;
  const tabIndex = isHidden ? 0 : -1;

  /* const menus = [
    { name: 'AFRICA', childNodes: [{ name: 'BOTSWANA', icon: '' }, { name: 'EGYPT', icon: '' }, { name: 'KENYA', icon: '' }, { name: 'MAURITIUS', icon: '' }, { name: 'MOROCCO', icon: '' }, { name: 'MOZAMBIQUE', icon: '' }, { name: 'NAMIBIA', icon: '' }, { name: 'THE SEYCHELLES', icon: '' }, { name: 'SOUTH AFRICA', icon: '' }, { name: 'TANZANIA', icon: '' }, { name: 'ZAMBIA', icon: '' }, { name: 'ZIMBABWE', icon: '' }] },
    { name: 'ASIA', childNodes: [{ name: 'CAMBODIA', icon: '' }, { name: 'CHINA', icon: '' }, { name: 'HONG KONG', icon: '' }, { name: 'INDONESIA', icon: '' }, { name: 'JAPAN', icon: '' }, { name: 'THE MALDIVES', icon: '' }, { name: 'SINGAPORE', icon: '' }, { name: 'SRI LANKA', icon: '' }, { name: 'THAILAND', icon: '' }, { name: 'VIETNAM', icon: '' }] },
    { name: 'THE CARIBBEAN', childNodes: [{ name: 'ARUBA', icon: '' }, { name: 'THE BAHAMAS', icon: '' }, { name: 'BRITISH VIRGIN ISLANDS', icon: '' }, { name: 'CUBA', icon: '' }, { name: 'THE DOMINICAN REPUBLIC', icon: '' }] },
    { name: 'CENTRAL AMERICA', childNodes: [{ name: 'BELIZE', icon: '' }, { name: 'COSTA RICA', icon: '' }, { name: 'GUATEMALA', icon: '' }, { name: 'HONDURAS', icon: '' }, { name: 'PANAMA', icon: '' }] },
    { name: 'THE MIDDLE EAST', icon: '🚌', childNodes: [{ name: 'EGYPT', icon: '' }, { name: 'JORDAN', icon: '' }, { name: 'TURKEY', icon: '' }, { name: 'UNITED ARAB EMIRATES', icon: '' }] },
    { name: 'NORTH AMERICA', childNodes: [{ name: 'NORTH AMERICA', icon: '' }, { name: 'CANADA', icon: '' }, { name: 'MEXICO', icon: '' }, { name: 'PUERTO RICO', icon: '' }, { name: 'USA', icon: '' }] },
    { name: 'OCEANIA', childNodes: [{ name: 'AUSTRALIA', icon: '' }, { name: 'FRENCH POLYNESIA', icon: '' }, { name: 'NEW ZEALAND', icon: '' }] },
    { name: 'SOUTH AMERICA', childNodes: [{ name: 'BOLIVIA', icon: '' }, { name: 'BRAZIL', icon: '' }, { name: 'COLOMBIA', icon: '' }, { name: 'ECUADOR', icon: '' }, { name: 'PERU', icon: '' }] },
    { name: 'EUROPE', childNodes: [{ name: 'AUSTRIA', icon: '' }, { name: 'BELGIUM', icon: '' }, { name: 'CROATIA', icon: '' }, { name: 'CZECH REPUBLIC', icon: '' }, { name: 'DENMARK', icon: '' }, { name: 'ENGLAND', icon: '' }, { name: 'FRANCE', icon: '' }, { name: 'GERMANY', icon: '' }, { name: 'GREECE', icon: '' }, { name: 'HUNGARY', icon: '' }, { name: 'ICELAND', icon: '' }, { name: 'IRELAND', icon: '' }, { name: 'ITALY', icon: '' }, { name: 'MALTA', icon: '' }, { name: 'THE NETHERLANDS', icon: '' }, { name: 'POLAND', icon: '' }, { name: 'PORTUGAL', icon: '' }, { name: 'SCOTLAND', icon: '' }, { name: 'SLOVAKIA', icon: '' }, { name: 'SPAIN', icon: '' }, { name: 'SWEDEN', icon: '' }, { name: 'SWITZERLAND', icon: '' }, { name: 'TURKEY', icon: '' }] },
  ]; */
  /* const menus = [
      {name: 'AFRICA', childNodes: [{name:"EGYPT",icon:""},{name:"MAURITIUS",icon:""},{name:"MOROCCO",icon:""}]},
      {name: 'ASIA', childNodes: [{name:"CAMBODIA",icon:""},{name:"CHINA",icon:""},{name:"HONG KONG",icon:""},{name:"INDONESIA",icon:""},{name:"JAPAN",icon:""},{name:"THE MALDIVES",icon:""},{name:"SINGAPORE",icon:""},{name:"SRI LANKA",icon:""},{name:"THAILAND",icon:""},{name:"VIETNAM",icon:""}]},
      {name: 'THE CARIBBEAN', childNodes: [{name:"ARUBA",icon:""},{name:"THE BAHAMAS",icon:""},{name:"BRITISH VIRGIN ISLANDS",icon:""},{name:"CUBA",icon:""},{name:"THE DOMINICAN REPUBLIC",icon:""}]},
      {name: 'CENTRAL AMERICA', childNodes: [{name:"BELIZE",icon:""},{name:"COSTA RICA",icon:""},{name:"GUATEMALA",icon:""},{name:"HONDURAS",icon:""},{name:"PANAMA",icon:""}]},
      {name: 'THE MIDDLE EAST', icon: "🚌", childNodes: [{name:"EGYPT",icon:""},{name:"JORDAN",icon:""},{name:"TURKEY",icon:""},{name:"UNITED ARAB EMIRATES",icon:""}]},
      {name: 'NORTH AMERICA', childNodes: [{name:"NORTH AMERICA",icon:""},{name:"CANADA",icon:""},{name:"MEXICO",icon:""},{name:"PUERTO RICO",icon:""},{name:"USA",icon:""}]},
      {name: 'OCEANIA', childNodes: [{name:"AUSTRALIA",icon:""},{name:"FRENCH POLYNESIA",icon:""},{name:"NEW ZEALAND",icon:""}]},
      {name: 'SOUTH AMERICA', childNodes: [{name:"BOLIVIA",icon:""},{name:"BRAZIL",icon:""},{name:"COLOMBIA",icon:""},{name:"ECUADOR",icon:""},{name:"PERU",icon:""}]},
      {name: 'EUROPE', childNodes: [{name:"AUSTRIA",icon:""},{name:"BELGIUM",icon:""},{name:"CROATIA",icon:""},{name:"CZECH REPUBLIC",icon:""},{name:"DENMARK",icon:""},{name:"ENGLAND",icon:""},{name:"FRANCE",icon:""},{name:"GERMANY",icon:""},{name:"GREECE",icon:""},{name:"HUNGARY",icon:""},{name:"ICELAND",icon:""},{name:"IRELAND",icon:""},{name:"ITALY",icon:""},{name:"MALTA",icon:""},{name:"THE NETHERLANDS",icon:""},{name:"POLAND",icon:""},{name:"PORTUGAL",icon:""},{name:"SCOTLAND",icon:""},{name:"SLOVAKIA",icon:""},{name:"SPAIN",icon:""},{name:"SWEDEN",icon:""},{name:"SWITZERLAND",icon:""},{name:"TURKEY",icon:""}]},
    ] */

  /* const {
    knowledge,
    vaccine,
    psychology,
    studying,
    booksandculture,
    books,
    art,
    culture,
    scienceandtech,
    science,
    tech,
    society,
    mode,
    relationships,
    foodanddrinks,
    video,
    humor,
    news,
    caricature,
  } = useTranslations(); */
  const trans = useTranslations();

  const items = site.siteMetadata.components.menu.links.map((item, index) => (
    <Grid item md={4} xs={12} key={index}>
      <section>
        <h3 style={{ color: '#262626' }}>
          {' '}
          <Typography
            type="div"
            style={{
              fontSize: '2rem',
              fontFamily: 'Almarai',
              fontWeight: 'bold',
              /* textAlign: 'justify', */
            }}
          >
            {trans[item.name]}
          </Typography>
        </h3>
        <Grid container>
          {
              item.childNodes.map((leaf, i) => (
                <Grid item xs={12} key={i}>
                  <a href={`/category/${leaf.name}`} tabIndex={i}>
                    <span aria-hidden="true">{leaf.icon}</span>
                    <Typography
                      type="div"
                      style={{
                        fontFamily: 'Almarai',
                        fontSize: '2rem',
                        /* textAlign: 'justify', */
                      }}
                    >
                      {trans[leaf.name]}
                    </Typography>
                  </a>
                </Grid>
              ))
            }

        </Grid>
      </section>
    </Grid>
  ));
  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <Grid container style={{ overflow: 'scroll' }}>
        {items}
      </Grid>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
