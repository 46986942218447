import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { EpubView, ReactReader } from 'react-reader';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Ebook = (props) => {
  const { url } = props;
  console.log('*******************');
  console.log('*******************');
  console.log('*******************');
  console.log(url);
  console.log('*******************');
  console.log('*******************');
  console.log('*******************');

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Sound
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            {' '}
            {/* Container needs a height.. */}
            <ReactReader
              url={`${url}`}
              title="Alice in wonderland"
              location={0}
              locationChanged={(epubcifi) => console.log(epubcifi)}
            />
          </div>

        </DialogContent>

      </Dialog>
    </div>
  );
};

Ebook.defaultProps = {
  url: 'alice.epub',
};

Ebook.propTypes = {
  url: PropTypes.string,
};

export default Ebook;
