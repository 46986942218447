import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'white',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    textAlign: 'right',
  },
  cover: {
    width: '100%',
    minWidth: '140px',
  },
  controls: {
    display: 'flex',
    alignItems: 'right',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function MediaControlCard() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>

      <Card className={classes.root}>
        {/* <div className={classes.details}> */}
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="body2">
            title
          </Typography>
          <Typography display="block" variant="caption" color="textSecondary">
            item.channel
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {`${'item.views'} • ${'item.createdAt'}`}
          </Typography>
        </CardContent>
        {/* <div className={classes.controls}>
            <IconButton aria-label="previous">
              {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
            </IconButton>
            <IconButton aria-label="play/pause">
              <PlayArrowIcon className={classes.playIcon} />
            </IconButton>
            <IconButton aria-label="next">
              {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
            </IconButton>
          </div> */}
        {/* </div> */}
        <CardMedia
          className={classes.cover}
          image="https://cdn.pixabay.com/photo/2014/10/23/20/51/iphone-500291_1280.jpg"
          title="Live from space album cover"
        />
      </Card>
    </>
  );
}
