import React from 'react';
import styled from 'styled-components';

const Underlined = styled.div`

font-family: Almarai;

@media only screen and (min-width: 992px) {

  text-decoration: none;
  background-repeat: no-repeat;

  transition: background-size 0.5s;
  position: relative;
  font-size: 26px;
  text-align: right;
  margin: 0 auto 1rem auto;
  padding: 0;

  &::before,&::after {
    position: absolute;
    content: "";
    bottom: -0.2rem;
    /*right: 2.5%;
    width: 95%;*/
    width: 100%;
    height: 1px;
    margin: 1px 0 0;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.3s;
    opacity: 1;
    background-image: linear-gradient(transparent calc(100% - 5px), #262626 5px);
    background-size: 100% 100%;

  }


}

  @media only screen and (max-width: 600px){

  }
  @media only screen and (max-width: 992px) {

  }
`;

export default Underlined;
