import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { Link } from 'gatsby';
import Title from './Title';

const useStyles = makeStyles({
  root: {
    minWidth: 75,
    width: '100%',
    margin: 0,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    textAlign: 'right',
    fontFamily: 'Almarai',
  },
  pos: {
    marginBottom: 2,
    textAlign: 'right',
  },
  rightTxtAlign: {
    textAlign: 'right',
    fontFamily: 'Almarai',
  },
  more: {
    color: 'red',
  },
  almarai: {
    fontFamily: 'Almarai',
  },

});

export default function SimpleCard(props) {
  const {
    title, excerpt, category, slug, timeToRead, description,
  } = props;
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>

      <Card className={classes.root} elevation={0}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {category}
          </Typography>
          <Typography className={classes.rightTxtAlign} variant="h5" component="h2">

            <Link to={slug}>
              <Title>{title}</Title>
            </Link>

          </Typography>
          <Typography className={classNames({ [classes.more]: true, [classes.almarai]: true, [classes.pos]: true })} color="textSecondary">
            صفة
          </Typography>
          <Typography className={classes.rightTxtAlign} variant="body2" component="div">
            {excerpt}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">
            <Typography component="div" className={classNames({ [classes.more]: true, [classes.almarai]: true })}>
              المزيد
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
