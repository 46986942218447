
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {MyCard} from "../../components"
import Grid from "@material-ui/core/Grid"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },
}));


export default function IconLabelTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper square className={classes.root}>
      <Grid container>

        <Grid item xs={8} >
           <MyCard image={"https://cdn.pixabay.com/photo/2015/03/17/14/05/sparkler-677774_1280.jpg"}/>
        </Grid>

        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={12}>
              <MyCard image={"https://cdn.pixabay.com/photo/2015/03/17/14/05/sparkler-677774_1280.jpg"}/>
            </Grid>
            <Grid item xs={12}>
              <MyCard image={"https://cdn.pixabay.com/photo/2015/03/17/14/05/sparkler-677774_1280.jpg"}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
