
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {MyCard} from "../../components"
/*
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
});

*/

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function IconLabelTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab value="one" icon={<PhoneIcon />} label="Neueste Gutscheine" />
        <Tab value="two" icon={<FavoriteIcon />} label="Exklusive Gutscheine" />
        <Tab value="three"  icon={<PersonPinIcon />} label="Beste Gutscheine" />
      </Tabs>

      <TabPanel value={value} index="one">
      {
        [...Array(10)].map((coupon)=>{
          return <MyCard image={"https://cdn.pixabay.com/photo/2015/03/17/14/05/sparkler-677774_1280.jpg"}/>
        })

      }
      </TabPanel>
      <TabPanel value={value} index="two">
      {
        [...Array(10)].map((coupon)=>{
          return <MyCard image={"https://cdn.pixabay.com/photo/2016/11/09/21/28/exclusive-banquet-1812772_1280.jpg"}/>
        })
      }
      </TabPanel>
      <TabPanel value={value} index="three">
      {
        [...Array(10)].map((coupon)=>{
          return <MyCard image={"https://cdn.pixabay.com/photo/2014/04/21/20/09/pinky-swear-329329_1280.jpg"}/>
        })
      }
      </TabPanel>
    </Paper>
  );
}
