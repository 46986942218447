import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import classNames from 'classnames';
import SimpleCard from './SimpleCard';
import Underlined from './Underlined';
import Vertical from './Vertical';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    /* border: '1px red solid', */
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: 'white',
    '&:before': {
      content: '',
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      height: '100px',
      background: 'black',
      bottom: 0,
    },
    '&:after': {
      content: '',
      display: 'block',
      position: 'absolute',
      right: 0,
      height: '100px',
      background: 'black',
      top: '1rem',
      bottom: '1rem',
      width: '10px',
    },

  },
  paper: {
    /* padding: theme.spacing(2), */
    margin: theme.spacing(2),
    // maxWidth: 500,
  },
  image: {
    width: 'auto',
    height: 'auto',
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: 'auto',
    borderRadius: '0',
  },

}));

export default function ComplexGrid() {
  const classes = useStyles();

  return (
    <div className={classNames({ [classes.root]: true })}>

      <Grid container spacing={0}>

        <Grid item xs={12} md={6} sm={12}>
          <SimpleCard />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <ButtonBase className={classes.image}>
            <img className={classes.img} alt="complex" src="https://cdn.pixabay.com/photo/2014/10/23/20/51/iphone-500291_1280.jpg" />
          </ButtonBase>
        </Grid>

      </Grid>

    </div>
  );
}
