import React from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import WebIcon from '@material-ui/icons/Web';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import footerStyles from './footer.module.scss';
// import footerStyles from "../assets/jss/material-kit-react/components/footerStyle.js";

// const useStyles = makeStyles(styles);

const Footer = () => (
  <footer className={footerStyles.wrapper}>
    <div className={footerStyles.footer}>
      <div className={footerStyles.main}>
        <Link to="/" className={footerStyles.ctpLogo}>
          <WebIcon />
        </Link>
        <div className={footerStyles.links}>
          <ul className={footerStyles.footerList}>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/about-data/license">License</Link>
            </li>
            <li>
              <a href="https://norafpost.com">
                Privacy Policy
              </a>
            </li>
            <li>
              <Link to="/about/accessibility">Accessibility</Link>
            </li>
            <li>
              <a href="/rss.xml">RSS</a>
            </li>
          </ul>
        </div>
        <ul className={footerStyles.social}>
          <li>
            <a href="https://norafpost.com">
              <GitHubIcon />
            </a>
          </li>
          <li>
            <a href="https://norafpost.com">
              <TwitterIcon />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <hr className={footerStyles.divider} />
    <div className={footerStyles.copyright}>
      <span>
        Copyright &copy;
        {' '}
        {new Date().getFullYear()}
        <Link to="/license">(CC BY 4.0)</Link>
      </span>
      <a href="#reach-skip-nav" className={footerStyles.backToTop} id="footer">
        <span>Back to top</span>
        <ArrowUpwardIcon />
      </a>
    </div>
  </footer>
);

export default Footer;
