import React, { Component } from 'react';
import styled from 'styled-components';

const StyledBurger = styled.button`
  position: absolute;
  top: 5%;/*too close to the top border*/
  left: 2rem;
  top: .8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: ${({ theme, open }) => (open ? 'black' : 'transparent')};
  border: none;
  cursor: pointer;
  padding: 5px 0 5px 0;
  z-index: 10;
  transition: all 0.3s linear;
  &:hover{
    border-radius: 0.2rem;
    background: ${({ theme, open }) => (open ? '#3569fa' : '#35fac6')};
    span {
      background: ${({ theme, open }) => (open ? theme.palette.primary : theme.palette.secondary)};
      :first-child {
        margin-bottom: ${({ theme, open }) => (open ? '0px' : '8px')};
      }

      :nth-child(2) {
      }

      :nth-child(3) {
          margin-top: ${({ theme, open }) => (open ? '0px' : '8px')};
      }
    }
  }

  span {
    width: 2rem;
    height: 0.25rem;
    background: ${({ theme, open }) => (open ? theme.palette.primary : theme.palette.secondary)};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'translate(0%, 0%) rotate(36deg)' : 'rotate(0)')};
      margin-bottom: 0px;
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'translate(0%, 0%) rotate(-36deg)' : 'rotate(0)')};
      margin-top: 0px;
    }
  }
`;

export default StyledBurger;
