import React from 'react';
import styled from 'styled-components';

const Title = styled.span`
font-family: Almarai;
@media only screen and (min-width: 992px) {

  /*width: calc(100%);*/
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.5s;
  position: relative;
  font-size: 26px;
  text-align: right;
  margin: 0 1rem auto auto;
  padding: 0;
  color: #4c4c4c;



  &::before,&::after {
    position: absolute;
    content: "";
    right: 0;
    bottom: -0.2rem;
    width: 0px;
    height: 0.8rem;
    margin: 5px 0 0;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.3s;
    /*background-color: red;*/
    opacity: 0;
    /*background-color: darken(orange,5%);*/
    background-image: linear-gradient(transparent calc(100% - 5px), #35fac6 5px);
  }

  &:hover {
    cursor: pointer;
    &::before,&::after {
      width: 100%;
      background-size: 100% 100%;
      opacity: 1;
    }

  }
}

  @media only screen and (max-width: 600px){

  }
  @media only screen and (max-width: 992px) {

  }
`;

export default Title;
