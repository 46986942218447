import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    /* maxWidth: '36ch', */
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(3),
  },
  inline: {
    display: 'inline',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function AlignItemsList(props) {
  const classes = useStyles();
  const { posts } = props;

  return (
    <List className={classes.root}>
      <Typography
        type="div"
        style={{
          fontFamily: 'Almarai',
          textAlign: 'justify',
          fontSize: '2rem',
          paddingRight: '1rem',

        }}
      >
        آراء
      </Typography>

      {posts.map((post, idx) => (
        <div key={idx}>
          <Link to={`/${post.node.slug}/`}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" variant="circular" className={classes.large} src={post.node.featuredImage.childImageSharp.fixed} />
              </ListItemAvatar>
              <ListItemText
                primary={(
                  <Typography
                    type="div"
                    style={{
                      fontFamily: 'Almarai',
                      textAlign: 'justify',
                    }}
                  >
                    {post.node.title}
                  </Typography>
                )}
                style={{ paddingRight: 20, textAlign: 'right' }}
                secondary={(
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {post.node.author}
                    </Typography>
                    <Typography
                      type="div"
                      style={{
                        fontFamily: 'Almarai',
                        textAlign: 'justify',
                      }}
                    >
                      {post.node.excerpt}
                    </Typography>

                  </>
          )}
              />
            </ListItem>
          </Link>
          <Divider variant="inset" component="li" />
        </div>
      ))}

    </List>
  );
}
